import React, { useContext, useEffect, useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { css } from '@compiled/react';
import { useLazyQuery } from '@apollo/react-hooks';
import { subDays, roundToNearestMinutes } from 'date-fns';

import Heading from '@atlaskit/heading';
import { Flex, Text } from '@atlaskit/primitives';
import { CustomItem, LinkItem, Section } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import GraphLineIcon from '@atlaskit/icon/glyph/graph-line';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { SPAViewContext } from '@confluence/spa-view-context';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition';
import {
	getContentAnalyticsLink,
	DialogPillName,
	dialogToPageAnalyticsTab,
} from '@confluence/confluence-analytics/entry-points/getContentAnalyticsLink';
import { OnboardingSubtleUpgradeEditionButton } from '@confluence/change-edition/entry-points/onboarding/OnboardingSubtleUpgradeEditionButton';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import { RecentViewersQuery } from './RecentViewersQuery.graphql';
import type {
	RecentViewersQueryVariables,
	RecentViewersQuery as RecentViewersQueryType,
} from './__types__/RecentViewersQuery';
import { useTeammatePresenceState } from './useTeammatePresenceStore';

const i18n = defineMessages({
	upsell: {
		id: 'experiment-teammate-presence.page-analytics.upsell',
		defaultMessage: 'Upgrade to see total page views',
		description:
			'This message appears on a button that triggers the upsell flow to Confluence Standard edition',
	},
	featureDescription: {
		id: 'experiment-teammate-presence.page-analytics.description',
		defaultMessage: 'See who’s viewed this page',
		description:
			'This message appears on a button that links to the page-analytics page. The page-analytics page shows who else has viewed the page.',
	},
	viewers: {
		id: 'experiment-teammate-presence.recent-viewers.viewers',
		defaultMessage:
			'{numViewers, plural, one {{numViewers} other viewed} other {{numViewers} others viewed}}',
		description:
			'This text is shown adjacent to icons that describes how many people have recently viewed the current page. The placeholder [numViewers] will be replaced with a number like 1, 2, 3, etc... Optimal characters is 28 or less',
	},
});

// This style overriding needs to be done cause there are no alternatives to the component
const containerStyles = css({
	display: 'flex',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > button': {
		padding: `${token('space.100')} ${token('space.200')}`,
		flex: 1,
		justifyContent: 'start',
		width: '100%',
		height: 'inherit',
		lineHeight: '20px',
		margin: 0,

		'&:hover': {
			flex: 1,
			justifyContent: 'start',
			width: '100%',
			padding: `${token('space.100')} ${token('space.200')}`,
			height: 'inherit',
			lineHeight: '20px',
			margin: 0,
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& > span': {
			display: 'flex',
			alignItems: 'center',
			gap: token('space.150'),
			flexGrow: 0,

			'&:hover': {
				display: 'flex',
				alignItems: 'center',
				gap: token('space.150'),
				flexGrow: 0,
			},
		},
	},
});

export const TeammatePresencePageAnalyticsItem = ({}: {}) => {
	const { isSiteAdmin } = useContext(SPAViewContext);
	const { edition } = useSessionData();
	const isEditPage = useIsEditorPage();

	const isFreeEdition = edition === ConfluenceEdition.FREE;
	if (isFreeEdition && !isSiteAdmin) return null;
	if (isEditPage) return null;

	const PageAnalyticsItem = isFreeEdition
		? TeammatePresencePageAnalyticsFreeItem
		: TeammatePresencePageAnalyticsStandardItem;

	return (
		<Section hasSeparator>
			<PageAnalyticsItem />
		</Section>
	);
};

export const TeammatePresencePageAnalyticsFreeItem = () => {
	return (
		<CustomItem
			component={() => {
				return (
					<div css={containerStyles}>
						<OnboardingSubtleUpgradeEditionButton
							analyticsProps={{
								actionSubjectId: 'teammatePresencePageAnalyticsUpgrade',
								source: 'onboarding',
								attributes: {},
							}}
							targetEdition={ConfluenceEdition.STANDARD}
						>
							<GraphLineIcon label="" primaryColor={token('color.icon.accent.purple')} />
							<FormattedMessage {...i18n.upsell} />
						</OnboardingSubtleUpgradeEditionButton>
					</div>
				);
			}}
		/>
	);
};

export const TeammatePresencePageAnalyticsStandardItem = () => {
	const contentTab = dialogToPageAnalyticsTab[DialogPillName?.VIEWS];
	const { contentId, contentType } = useTeammatePresenceState();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [getRecentViewersQuery, { data, loading, error, called }] = useLazyQuery<
		RecentViewersQueryType,
		RecentViewersQueryVariables
	>(RecentViewersQuery);

	const yesterdayTimestamp = subDays(new Date(), 1);
	const yesterdayRoundedIso = roundToNearestMinutes(yesterdayTimestamp, {
		nearestTo: 30,
	}).toISOString();

	useEffect(() => {
		if (called) return;
		getRecentViewersQuery({
			variables: {
				contentId,
				limit: 100,
				startTime: yesterdayRoundedIso,
			},
		});
	}, [yesterdayRoundedIso, called, contentId, getRecentViewersQuery]);

	const viewers = data?.countGroupByUser?.nodes ?? [];
	const canShowSubTitle = Boolean(!loading && !error && viewers.length);

	const href =
		useMemo(
			() => getContentAnalyticsLink({ contentTab, contentId, contentType }),
			[contentTab, contentId, contentType],
		) ?? '#';

	const handleClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'getPageInsights',
				source: 'presence',
			},
		}).fire();
	};

	return (
		<LinkItem href={href} target="_blank" onClick={handleClick}>
			<Flex alignItems="center" gap="space.150">
				<GraphLineIcon label="" primaryColor={token('color.icon.accent.purple')} />
				<Flex alignItems="start" direction="column" gap="space.050">
					<Heading size="xxsmall">
						<FormattedMessage {...i18n.featureDescription} />
					</Heading>
					{canShowSubTitle && (
						<Text size="small" color="color.text.subtle">
							<FormattedMessage {...i18n.viewers} values={{ numViewers: viewers.length }} />
						</Text>
					)}
				</Flex>
			</Flex>
		</LinkItem>
	);
};
